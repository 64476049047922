// Unpublished Work © 2021-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DataTable from 'Ui/components/common/data-table/data-table';
import EditLink from 'Ui/components/common/data-table/edit-link';
import EquipmentName from 'OnEquip/equipment/common/data-table/equipment-name';
import Duration from 'OnEquip/equipment/service-history/data-table/duration';
import {deleteService} from 'Services/maintenance-service';
import {getMaintenanceDue} from 'Services/membership-service';
import {fetchEffectData} from 'Utils/react-utils';
import {replaceTranslationNames} from 'Utils/translation-utils';
import {formatTimeOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import {
    alphaNumericCompare,
    dateCompare,
    formatAssignees,
    formatModelAndManufacturerName, genericServiceCompare,
    numericCompare
} from 'Ui/models/maintenance';
import {closeDialog, openDialog} from 'Store/actions/dialogs';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import dialogTypes from 'Ui/components/common/dialog-types';
import {useEquipmentContext} from 'OnEquip/equipment/context/equipment-context';
import {HISTORY} from 'OnEquip/equipment/constants/equipment-details-tabs';
import permissionsConstants from 'Common/constants/permissions';
import {SERVICE_HISTORY} from 'Common/constants/routes';
import {COMPLETED, DECLINED} from 'Common/constants/service-status';
import accounting from 'accounting';
import {TOAST_TYPE} from '@deere/toast';
import {VIEW_SERVICE, MANAGE_SERVICE, VIEW_EQUIPMENT_DETAILS} from 'Common/constants/business-activities';
import {isAuthorized} from 'Common/utils/authorization-handler';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {IconDownload} from '@deere/icons';
import {exportServiceHistoryReport} from 'Services/excel-service';

function getColumns(translations, membershipId, history) {
    const onequipUrl = `${window.props.onLinkLegacyUri}/onequip/#m/${membershipId}/fleet/{0}/serviceHistory`;
    const equipmentUrl = `${onequipUrl}/equipment/{1}`;
    const serviceUrl = `${SERVICE_HISTORY}/serviceForm?serviceId={0}`;

    return [
        {
            Header: translations.DATE,
            accessor(row) {
                return row;
            },
            id: 'formattedTime',
            width: 155,
            sortMethod(a, b) {
                return genericServiceCompare(a, b, dateCompare, 'unFormattedTime');
            },
            Cell(row) {
                return row.original.formattedTime;
            }
        },
        {
            Header: translations.ONLINK_ASSIGNEES,
            accessor: 'assignees',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.EQUIPMENT_LIST,
            accessor: 'equipmentName',
            sortMethod: alphaNumericCompare,
            Cell(row) {
                return (
                    <EquipmentName
                        equipment={row.original}
                        requiredMyJdPermissions={[VIEW_EQUIPMENT_DETAILS]}
                        tab={HISTORY}
                        type={SERVICE_HISTORY}
                        url={equipmentUrl}
                    />
                );
            }
        },
        {
            Header: translations.MODEL,
            accessor: 'modelAndManufacturerName',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.ONLINK_GROUP,
            accessor: 'serviceGroup',
            sortMethod: alphaNumericCompare
        },
        {
            Header: translations.ONLINK_SERVICE,
            accessor: 'serviceTypeName',
            sortMethod: alphaNumericCompare,
            Cell(row) {
                const {original: service} = row;

                const filledInServiceUrl = serviceUrl.replace('{0}', service.serviceId);

                return (
                    <EditLink
                        onClick={() => history.push(filledInServiceUrl)}
                        requiredMyJdPermissions={[VIEW_SERVICE]}
                    >
                        {service.serviceTypeName}
                    </EditLink>
                );
            }
        },
        {
            Header: replaceTranslationNames(translations.DURATION_WITH_UNIT, {
                '0': translations.mins
            }),
            accessor(row) {
                return row;
            },
            id: 'actualDuration',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            width: 150,
            sortMethod(a, b) {
                if (a.status === DECLINED && b.status !== DECLINED) {
                    return 1;
                } else if (a.status !== DECLINED && b.status === DECLINED) {
                    return -1;
                }

                return numericCompare(a.actualDuration, b.actualDuration);
            },
            Cell(row) {
                return (
                    <Duration
                        service={row.original}
                        translations={translations}
                    />
                );
            }
        },
        {
            Header: translations.HOURS,
            accessor: 'totalHours',
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            width: 70,
            Cell(row) {
                return accounting.formatNumber(row.original.totalHours, 1);
            }
        }
    ];
}

function initializeState() {
    const [services, setServices] = React.useState(() => []);
    const [loading, setLoading] = React.useState(() => false);

    return {
        services,
        setServices,
        loading,
        setLoading
    };
}

function ServiceHistory(props) {
    const {
        addToast,
        closeConfirmation,
        history,
        isMigrated,
        membershipId,
        myJdPermissions,
        openConfirmation,
        permissions,
        superUser,
        translations
    } = props;

    const {
        services,
        setServices,
        loading,
        setLoading
    } = initializeState();

    const equipmentContext = useEquipmentContext();

    const canDeleteServices = React.useMemo(() => {
        return isAuthorized({
            myJdPermissions: [MANAGE_SERVICE],
            permission: permissionsConstants.DELETE_SERVICE_TICKETS
        }, {
            isMigrated,
            myJdPermissions,
            permissions,
            superUser
        });
    }, [isMigrated, myJdPermissions, permissions, superUser]);

    equipmentContext.current = async (isMounted = () => true) => {
        setLoading(true);

        const services = await getMaintenanceDue(`${COMPLETED},${DECLINED}`);

        if (isMounted()) {
            const formattedServices = services.map((service) => ({
                ...service,
                assignees: formatAssignees(service),
                formattedTime: formatTimeOrDefault(service.timeCompleted) || formatTimeOrDefault(service.dateScheduled),
                unFormattedTime: service.timeCompleted || service.dateScheduled,
                modelAndManufacturerName: formatModelAndManufacturerName(service)
            }));

            setServices(formattedServices);
            setLoading(false);
        }
    };

    React.useEffect(() => fetchEffectData(equipmentContext.current), [membershipId]);

    async function deleteServiceHistory(data) {
        const serviceId = data.serviceId;
        const serviceName = data.serviceTypeName;

        openConfirmation({
            message: translations.ONLINK_SERVICE_DELETE_CONFIRMATION_MESSAGE,
            title: translations.ONLINK_DELETE_SERVICE,
            async onContinue() {
                closeConfirmation();

                try {
                    await deleteService(serviceId);

                    await equipmentContext.current();
                } catch (e) {
                    const message = replaceTranslationNames(translations.ONLINK_SERVICE_DELETE_ERROR, {
                        '0': serviceName
                    });

                    addToast({
                        message,
                        type: TOAST_TYPE.ERROR
                    });
                }
            },
            onCancel: closeConfirmation
        });
    }

    useNavBarActions([{
        disabled: loading,
        Icon: <IconDownload
            iconDownload={{
                style: {
                    height: '20px',
                    width: '20px',
                    fill: '#fff'
                }
            }}
        />,
        onClick: () => exportServiceHistoryReport(services, translations),
        myJdPermissions: VIEW_EQUIPMENT_DETAILS,
        title: 'EXPORT',
        variant: 'primary'
    }]);

    return (
        <DataTable
            columns={getColumns(translations, membershipId, history)}
            defaultSorted={[{
                desc: true,
                id: 'formattedTime'
            }]}
            deleteHandler={deleteServiceHistory}
            loading={loading}
            rows={services}
            searchable={true}
            showDeleteColumn={canDeleteServices}
            showPagination={true}
            translations={translations}
        />
    );
}

ServiceHistory.propTypes = {
    addToast: PropTypes.func,
    closeConfirmation: PropTypes.func,
    history: PropTypes.history,
    isMigrated: PropTypes.bool,
    membershipId: PropTypes.string,
    myJdPermissions: PropTypes.myJdPermissions,
    openConfirmation: PropTypes.func,
    permissions: PropTypes.legacyPermissions,
    superUser: PropTypes.bool,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        isMigrated: state.membership.isMigrated,
        membershipId: state.membership.membershipId,
        myJdPermissions: state.account.myJdPermissions,
        permissions: state.account.permissions,
        superUser: state.account.superUser
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        },
        closeConfirmation() {
            dispatch(closeDialog(dialogTypes.CONFIRMATION_DIALOG));
        },
        openConfirmation(props) {
            dispatch(openDialog(dialogTypes.CONFIRMATION_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServiceHistory));
