// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {fetchEffectData, useDeepEffect} from 'Utils/react-utils';
import LifetimeReportChart from 'Ui/features/onequip/reports/lifetime-report/lifetime-report-chart';
import LifetimeReportDataTable from 'Ui/features/onequip/reports/lifetime-report/lifetime-report-data-table';
import NoDataMessage from 'Ui/components/common/message/no-data-message';
import {getTableData} from 'Ui/features/onequip/reports/lifetime-report/lifetime-report-utils';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {VIEW_EQUIPMENT_DETAILS} from 'Common/constants/business-activities';
import {IconDownload} from '@deere/icons';
import {exportLifetimeUptimeReport} from 'Services/excel-service';

function EquipmentLifetimeReport(props) {
    const {
        featureToggles,
        membership,
        primarySelector,
        secondarySelector,
        setLoading,
        translations,
        loading
    } = props;

    const [lifetimeData, setLifetimeData] = React.useState({
        chartData: {},
        tableData: []
    });

    useDeepEffect(() => fetchEffectData(async (isMounted) => {
        let tableData = [],
            chartData = {};

        try {
            [tableData, chartData] = await getTableData(
                primarySelector,
                secondarySelector,
                membership.fleetId,
                {
                    currencyPreference: membership.currencyPreference,
                    featureToggles
                },
                translations
            );
        } finally {
            if (isMounted()) {
                setLifetimeData({
                    chartData,
                    tableData
                });
                setLoading((prevLoading) => ({
                    ...prevLoading,
                    dateSelect: false,
                    primarySelector: false,
                    secondarySelector: false
                }));
            }
        }
    }), [featureToggles, membership.currencyPreference, membership.fleetId, primarySelector, secondarySelector, translations]);

    useNavBarActions([{
        disabled: loading.equipment,
        Icon: <IconDownload
            iconDownload={{
                style: {
                    height: '20px',
                    width: '20px',
                    fill: '#fff'
                }
            }}
        />,
        onClick: () => exportLifetimeUptimeReport(lifetimeData.tableData, primarySelector, translations),
        myJdPermissions: VIEW_EQUIPMENT_DETAILS,
        title: 'EXPORT',
        variant: 'primary'
    }]);

    return (
        <NoDataMessage
            hasData={lifetimeData.tableData.length > 0}
            noDataMessage={translations.ONLINK_NO_DATA_GRAPH}
        >
            <div>
                <div className='chart-container'>
                    <LifetimeReportChart
                        chartData={lifetimeData.chartData}
                        currencyPreference={membership.currencyPreference}
                        featureToggles={featureToggles}
                        primarySelector={primarySelector}
                        translations={translations}
                    />
                </div>
                <LifetimeReportDataTable
                    primarySelector={primarySelector}
                    secondarySelector={secondarySelector}
                    tableData={lifetimeData.tableData}
                    translations={translations}
                />
            </div>
        </NoDataMessage>
    );
}

EquipmentLifetimeReport.propTypes = {
    featureToggles: PropTypes.featureToggles,
    loading: PropTypes.object,
    membership: PropTypes.membership,
    primarySelector: PropTypes.string,
    secondarySelector: PropTypes.string,
    setLoading: PropTypes.func,
    translations: PropTypes.translations
};

export default EquipmentLifetimeReport;
