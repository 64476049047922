// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {fetchEffectData} from 'Utils/react-utils';
import {getEquipmentCosts} from 'Services/equipment-service';
import CostReportChart from 'Ui/features/onequip/reports/cost-report/cost-report-chart';
import CostReportDataTable from 'Ui/features/onequip/reports/cost-report/cost-report-data-table';
import NoDataMessage from 'Ui/components/common/message/no-data-message';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {IconDownload} from '@deere/icons';
import {VIEW_EQUIPMENT_DETAILS} from 'Common/constants/business-activities';
import {exportEquipmentCostReport} from 'Services/excel-service';

function aggregateData(equipmentCostRollup) {
    const aggregatedData = {
        advanceLeasePayments: 0,
        leasePayments: 0,
        partsCost: 0,
        propertyTax: 0,
        purchase: 0,
        salesTax: 0,
        serviceCost: 0,
        totalCost: 0,
        finalLeasePayments: 0
    };

    equipmentCostRollup.dateChildren?.forEach((rollupData) => {
        aggregatedData.advanceLeasePayments += rollupData.advanceLeasePayments;
        aggregatedData.leasePayments += rollupData.leasePayments;
        aggregatedData.finalLeasePayments += rollupData.finalLeasePayments;
        aggregatedData.partsCost += rollupData.partsCost;
        aggregatedData.propertyTax += rollupData.propertyTax;
        aggregatedData.purchase += rollupData.purchase;
        aggregatedData.salesTax += rollupData.salesTax;
        aggregatedData.serviceCost += rollupData.serviceCost;
        aggregatedData.totalCost += rollupData.totalCost - (rollupData.finalLeasePayments + rollupData.leasePayments + rollupData.advanceLeasePayments);
    });

    return aggregatedData;
}

function getDateChildren(equipmentCostRollup) {
    return equipmentCostRollup.dateChildren?.map((rollup) => {
        return {
            ...rollup,
            totalCost: rollup.totalCost - (rollup.finalLeasePayments + rollup.leasePayments + rollup.advanceLeasePayments)
        };
    });
}

function transformData(equipmentCostRollup) {
    if (equipmentCostRollup.dateScale === 'daily') {
        return {
            ...equipmentCostRollup,
            totalCost: equipmentCostRollup.totalCost -
            (equipmentCostRollup.finalLeasePayments + equipmentCostRollup.leasePayments + equipmentCostRollup.advanceLeasePayments),
            dateChildren: [{
                advanceLeasePayments: equipmentCostRollup.advanceLeasePayments,
                dateScale: equipmentCostRollup.dateScale,
                dateSelect: equipmentCostRollup.dateSelect,
                leasePayments: equipmentCostRollup.leasePayments,
                partsCost: equipmentCostRollup.partsCost,
                propertyTax: equipmentCostRollup.propertyTax,
                purchase: equipmentCostRollup.purchase,
                salesTax: equipmentCostRollup.salesTax,
                serviceCost: equipmentCostRollup.serviceCost,
                totalCost: equipmentCostRollup.totalCost -
                (equipmentCostRollup.finalLeasePayments + equipmentCostRollup.leasePayments + equipmentCostRollup.advanceLeasePayments)
            }]
        };
    }

    return {
        ...equipmentCostRollup,
        ...aggregateData(equipmentCostRollup),
        dateChildren: getDateChildren(equipmentCostRollup)
    };
}

async function fetchEquipmentCosts(tertiarySelector, dateSelect) {
    try {
        const {equipmentCostRollup = {}} = await getEquipmentCosts(tertiarySelector, dateSelect);

        return equipmentCostRollup;
    } catch (error) {
        return {};
    }
}

function EquipmentCostReport(props) {
    const {
        dateSelect,
        featureToggles,
        membership,
        setLoading,
        tertiarySelector,
        translations
    } = props;

    const [equipmentCostRollup, setEquipmentCostRollup] = React.useState({});

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        let equipmentCostRollupData = {};

        if (tertiarySelector && dateSelect) {
            equipmentCostRollupData = await fetchEquipmentCosts(tertiarySelector, dateSelect);
        }

        if (isMounted()) {
            const transformedData = Object.keys(equipmentCostRollupData).length > 0 ?
                transformData(equipmentCostRollupData) :
                {};

            setEquipmentCostRollup(transformedData);
            setLoading((prevLoading) => ({
                ...prevLoading,
                dateSelect: false,
                primarySelector: false,
                secondarySelector: false,
                tertiarySelector: false
            }));
        }
    }), [dateSelect, tertiarySelector]);

    useNavBarActions([{
        disabled: false,
        Icon: <IconDownload
            iconDownload={{
                style: {
                    height: '20px',
                    width: '20px',
                    fill: '#fff'
                }
            }}
        />,
        onClick: () => exportEquipmentCostReport(equipmentCostRollup, translations),
        myJdPermissions: VIEW_EQUIPMENT_DETAILS,
        title: 'EXPORT',
        variant: 'primary'
    }]);

    return (
        <NoDataMessage
            hasData={Object.keys(equipmentCostRollup).length > 0}
            noDataMessage={translations.ONLINK_NO_DATA_GRAPH}
        >
            <div>
                <div className='chart-container'>
                    <CostReportChart
                        costReportData={equipmentCostRollup}
                        featureToggles={featureToggles}
                        membership={membership}
                        translations={translations}
                    />
                </div>
                <CostReportDataTable
                    costReportData={equipmentCostRollup}
                    featureToggles={featureToggles}
                    membership={membership}
                    translations={translations}
                />
            </div>
        </NoDataMessage>
    );
}

EquipmentCostReport.propTypes = {
    dateSelect: PropTypes.string,
    featureToggles: PropTypes.featureToggles,
    membership: PropTypes.membership,
    setLoading: PropTypes.func,
    tertiarySelector: PropTypes.string,
    translations: PropTypes.translations
};

export default EquipmentCostReport;
