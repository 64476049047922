// Unpublished Work © 2022-2024 Deere & Company.

import React from 'react';
import PropTypes from 'Utils/prop-type-utils';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DataTable from 'Ui/components/common/data-table/data-table';
import EquipmentActions from 'Ui/components/common/data-table/equipment-actions';
import EquipmentName from 'OnEquip/equipment/common/data-table/equipment-name';
import Status from 'Ui/components/common/data-table/status';
import TelematicsEnabled from 'Ui/components/common/data-table/telematics-enabled';
import {useEquipmentContext} from 'OnEquip/equipment/context/equipment-context';
import {MAINTENANCE} from 'OnEquip/equipment/constants/equipment-details-tabs';
import equipmentStatus from 'Common/constants/equipment-status';
import {ONLINK_NAVIGATION_REDESIGN} from 'Common/constants/feature-toggles';
import {FLEET} from 'Common/constants/routes';
import {getFleetByMembershipId, getFleetEquipment} from 'Services/membership-service';
import {fetchEffectData} from 'Utils/react-utils';
import {redirect} from 'Utils/redirect-utils';
import {formatNumberOrDefault, formatStatus, formatTimeOrDefault} from 'OnEquip/equipment/utils/equipment-detail-formatters';
import {formatModelName} from 'Ui/models/equipment-model';
import {openDialog} from 'Store/actions/dialogs';
import {addToast as addReduxToast} from 'Store/actions/toasts';
import dialogTypes from 'Ui/components/common/dialog-types';
import MediaQuery, {MOBILE_MEDIA_QUERY} from 'Ui/components/higher-order-components/media-query';
import {dateCompare, numericCompare} from 'Ui/models/maintenance';
import PartsCatalogLink from 'Ui/components/common/data-table/parts-catalog-link';
import {CATEGORY, ACTIONS, LABELS} from 'Utils/analytics-utils';
import {
    getFilterComponent,
    getAdditionalTableOptionRow,
    getFleetLegend
} from 'Ui/features/onequip/equipment/fleet/fleet-table-headers';
import {useNavBarActions} from 'Ui/react-hooks/use-navbar-actions';
import {exportFleetReport} from 'Services/excel-service';
import {EDIT_EQUIPMENT_LIST, MANAGE_EQUIPMENT_SETTINGS, EDIT_SERVICE, VIEW_EQUIPMENT_DETAILS} from 'Common/constants/business-activities';
import {translateEquipmentArea} from 'Utils/translation-utils';
import {Tooltip} from '@deere/isg.component-library/lib/Tooltip/Tooltip';
import Box from '@mui/material/Box';

const THREE_BUTTON_WIDTH = 78;

function getOnEquipUrl(membershipId) {
    return `${window.props.onLinkLegacyUri}/onequip/#m/${membershipId}`;
}

function getColumns(translations, membershipId, updateFleetEquipment, history) {
    const onequipUrl = getOnEquipUrl(membershipId);

    return [
        {
            accessor: 'lastReportedMtgId',
            width: 30,
            Cell(row) {
                return (
                    <TelematicsEnabled equipment={row.original}/>
                );
            }
        },
        {
            Header: translations.AREA,
            accessor: 'equipmentArea',
            width: 130
        },
        {
            Header: translations.NAME,
            accessor: 'formattedName',
            Cell(row) {
                const equipmentUrl = `${onequipUrl}/fleet/{0}/equipment/{1}`;

                return (
                    <Tooltip
                        placement='top'
                        title={row.original.serialNumber}
                    >
                        <Box
                            width='fit-content'
                        >
                            <EquipmentName
                                equipment={row.original}
                                requiredMyJdPermissions={[VIEW_EQUIPMENT_DETAILS]}
                                tab={MAINTENANCE}
                                type={FLEET}
                                url={equipmentUrl}
                            />
                        </Box>
                    </Tooltip>
                );
            }
        },
        {
            Header: translations.TYPE,
            accessor: 'equipmentType'
        },
        {
            Header: translations.BRAND,
            accessor: 'manufacturerName'
        },
        {
            Header: translations.MODEL,
            accessor: 'modelDescription',
            Cell(row) {
                return (
                    <PartsCatalogLink
                        analyticsEventData={{
                            category: CATEGORY.PARTS_CATALOG,
                            label: LABELS.MODEL_CODE,
                            action: ACTIONS.FLEET_MODEL,
                            value: row.original.modelCode
                        }}
                        manufacturerId={row.original.manufacturerId}
                        url={`https://partscatalog.deere.com/jdrc/search/type/model/term/${row.original.modelDescription}`}
                        value={row.original.modelDescription}
                    />
                );
            }
        },
        {
            Header: translations.STATUS,
            accessor: 'formattedStatus',
            Cell(row) {
                return (
                    <Status
                        formattedStatus={row.original.formattedStatus}
                        status={row.original.status}
                    />
                );
            }
        },
        {
            Header: translations.ONLINK_LAST_ENGINE_HOURS_UPDATE,
            accessor(row) {
                return row;
            },
            id: 'timeLastMeterReading',
            width: 255,
            sortMethod(a, b) {
                return dateCompare(a.timeLastMeterReading, b.timeLastMeterReading);
            },
            Cell(row) {
                return row.original.formattedTimeLastMeterReading;
            }
        },
        {
            Header: translations.HOURS,
            accessor(row) {
                return row;
            },
            className: 'right-aligned',
            headerClassName: 'right-aligned',
            id: 'formattedTotalHours',
            width: 100,
            sortMethod(a, b) {
                return numericCompare(a.totalHours, b.totalHours);
            },
            Cell(row) {
                return row.original.formattedTotalHours;
            }
        },
        {
            accessor: 'actions',
            resizable: false,
            sortable: false,
            width: THREE_BUTTON_WIDTH,
            Cell(row) {
                return (
                    <EquipmentActions
                        equipment={row.original}
                        history={history}
                        translations={translations}
                        updateFleetEquipment={updateFleetEquipment}
                    />
                );
            }
        }
    ];
}

function getNavBarActions(onAddEquipmentClick, loading, fleet, translations, openBulkEngineHoursDialog, history) {
    return [
        {
            onClick: onAddEquipmentClick,
            title: 'EQUIPMENT',
            Icon: 'icon-add',
            variant: 'primary',
            disabled: loading,
            requiredMyJdPermissions: EDIT_EQUIPMENT_LIST
        },
        {
            onClick: () => exportFleetReport(fleet, translations),
            title: 'EXPORT',
            Icon: 'icon-wdt',
            disabled: loading,
            requiredMyJdPermissions: EDIT_EQUIPMENT_LIST
        },
        {
            onClick: openBulkEngineHoursDialog,
            title: 'ONLINK_UPDATE_HOURS',
            Icon: 'icon-history',
            disabled: loading,
            requiredMyJdPermissions: EDIT_EQUIPMENT_LIST
        },
        {
            title: 'ONLINK_SERVICE',
            Icon: 'icon-wrench',
            onClick: () => history.push(`${FLEET}/serviceForm`),
            requiredMyJdPermissions: EDIT_SERVICE
        },
        {
            onClick: () => redirect(`${FLEET}/settings/fleet`, history),
            title: 'SETTINGS',
            Icon: 'icon-gear',
            requiredMyJdPermissions: MANAGE_EQUIPMENT_SETTINGS
        }
    ];
}

function initializeState() {
    const [fleet, setFleet] = React.useState(() => []);
    const [loading, setLoading] = React.useState(() => true);
    const [showArchived, setShowArchived] = React.useState(() => false);

    return {
        fleet,
        setFleet,
        loading,
        setLoading,
        showArchived,
        setShowArchived
    };
}

function Fleet(props) {
    const {
        addToast,
        featureToggles,
        history,
        isMobile,
        membershipId,
        openBulkEngineHoursDialog: openBulkEngineHours,
        openEquipmentCreateDialog,
        translations
    } = props;

    const {
        fleet,
        setFleet,
        loading,
        setLoading,
        showArchived,
        setShowArchived
    } = initializeState();

    const equipmentContext = useEquipmentContext();
    const membershipFleetId = React.useRef();

    equipmentContext.current = async (isMounted = () => true) => {
        setLoading(true);

        const {equipment} = await getFleetEquipment();

        if (isMounted()) {
            const formatted = equipment.map((equipment) => {
                const formattedName = equipment.fleetId !== equipment.fleetInUseId && equipment.fleetName ?
                    `${equipment.equipmentName} [${equipment.fleetName}]` :
                    equipment.equipmentName;

                return {
                    ...equipment,
                    equipmentArea: translateEquipmentArea(translations, equipment.equipmentArea) || translations.ONLINK_UNASSIGNED,
                    formattedTotalHours: formatNumberOrDefault(equipment.totalHours, 1),
                    formattedName,
                    modelDescription: formatModelName(equipment),
                    formattedStatus: formatStatus(equipment, translations),
                    formattedTimeLastMeterReading: formatTimeOrDefault(equipment.timeLastMeterReading)
                };
            });

            setFleet(formatted);
            setLoading(false);
        }
    };

    function openBulkEngineHoursDialog() {
        openBulkEngineHours({
            addToast,
            membershipId,
            translations,
            title: translations.ONLINK_BULK_ENGINE_HOUR_UPDATE,
            updateFleetEquipment: equipmentContext.current
        });
    }

    function onAddEquipmentClick() {
        openEquipmentCreateDialog({
            fleetId: membershipFleetId.current,
            updateFleetEquipment: equipmentContext.current
        });
    }

    React.useEffect(() => fetchEffectData(async (isMounted) => {
        setLoading(true);

        const {fleets} = await getFleetByMembershipId(membershipId);

        membershipFleetId.current = fleets[0].fleetId;

        await equipmentContext.current(isMounted);
    }), [membershipId]);

    const filteredFleet = showArchived ?
        fleet :
        fleet.filter((equipment) => equipment.status !== equipmentStatus.archived);

    useNavBarActions(featureToggles[ONLINK_NAVIGATION_REDESIGN] ? getNavBarActions(
        onAddEquipmentClick,
        loading,
        fleet,
        translations,
        openBulkEngineHoursDialog,
        history
    ) : []);

    return (
        <DataTable
            additionalTableOptionRow={!featureToggles[ONLINK_NAVIGATION_REDESIGN] &&
                getAdditionalTableOptionRow(translations, onAddEquipmentClick, isMobile)}
            columns={getColumns(translations, membershipId, equipmentContext.current, history)}
            defaultSorted={[{
                desc: false,
                id: 'equipmentArea'
            }]}
            filterComponent={
                getFilterComponent(
                    filteredFleet,
                    loading,
                    showArchived,
                    setShowArchived,
                    translations,
                    openBulkEngineHoursDialog,
                    onAddEquipmentClick,
                    history,
                    isMobile,
                    featureToggles
                )
            }
            legendComponent={getFleetLegend(translations)}
            loading={loading}
            rows={filteredFleet}
            searchable={true}
            translations={translations}
        />
    );
}

Fleet.propTypes = {
    addToast: PropTypes.func,
    featureToggles: PropTypes.featureToggles,
    history: PropTypes.history,
    isMobile: PropTypes.bool,
    membershipId: PropTypes.string,
    openBulkEngineHoursDialog: PropTypes.func,
    openEquipmentCreateDialog: PropTypes.func,
    translations: PropTypes.translations
};

export function mapStateToProps(state) {
    return {
        featureToggles: state.account.featureToggles,
        membershipId: state.membership.membershipId
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addToast(value) {
            dispatch(addReduxToast(value));
        },
        openBulkEngineHoursDialog(props) {
            dispatch(openDialog(dialogTypes.BULK_ENGINE_HOURS_DIALOG, props));
        },
        openEquipmentCreateDialog(props) {
            dispatch(openDialog(dialogTypes.EQUIPMENT_CREATE_DIALOG, props));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaQuery(MOBILE_MEDIA_QUERY)(withRouter(Fleet)));
